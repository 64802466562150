<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>
            <!-- name -->
            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="title"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`title.${k}`"
                    :name="`${i} Title`"
                    rules="required"
                  >
                    <b-form-group :label="`${i} Title`">
                      <b-form-input
                        v-model="form.title[k]"
                        :placeholder="`${i} Title`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col
                cols="12"
                class="my-2"
            >
              <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">Image</legend>
              <ValidationProvider
                  v-slot="{ errors }"
                  vid="image"
                  name="Image"
              >
                <MediaLibraryAttachment
                    ref="imageMediaComponent"
                    :initial-value="image"
                    :max-items="1"
                    name="image"
                    :headers="headers"
                    routePrefix="media-library"
                    :uploadDomain="baseURL"
                    @change="getFiles($event,'image_uuid')"
                    :validation-rules="{ accept: ['image/webp'], maxSizeInKB: 2048 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>

            <b-col
                cols="12"
                class="my-2"
            >
              <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">Icon</legend>
              <ValidationProvider
                  v-slot="{ errors }"
                  vid="icon"
                  name="Icon"
              >
                <MediaLibraryAttachment
                    ref="iconMediaComponent"
                    :initial-value="icon"
                    :max-items="1"
                    name="icon"
                    :headers="headers"
                    routePrefix="media-library"
                    :uploadDomain="baseURL"
                    @change="getFiles($event,'icon_uuid')"
                    :validation-rules="{ accept: ['image/svg+xml'], maxSizeInKB: 2048 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>

            <!-- checkbox -->
            <b-col cols="12">
              <b-card-text class="mb-0">
                Status
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { serialize } from 'object-to-formdata'
import _ from 'lodash'
import { baseURL } from '@/services/url.service'
import MediaLibraryAttachment from '../../components/media-spatie/media-library-pro-vue2-attachment/src/MediaLibraryAttachment.vue'

export default {
  components: {
    MediaLibraryAttachment,
  },
  mixins: [formMixin],
  data() {
    return {
      imageAfterUpload: [],
      baseURL: baseURL(),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      icon: null,
      image: null,
      image_uuid: null,
      icon_uuid: null,
      validationErrors: {
        image: 'The Image field is required',
        icon: 'The Icon field is required',
      },
      form: {
        title: {
          en: null,
          ar: null,
        },
        image_uuid: null,
        icon_uuid: null,
        status: 'active',
      },
      form_data: null,
    }
  },
  watch: {
    icon(val) {
      if (val) {
        this.form.icon = val
      } else {
        this.form.icon = 'delete'
      }
    },
  },
  created() {
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    getFiles(e, field) {
      const arr = []
      arr.push(e)
      this.imageAfterUpload = arr
      this.form[field] = Object.keys(this.imageAfterUpload[0])[0]
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            title: data.title_value,
            status: data.status,
            image_uuid: data.image?.uuid || null,
            icon_uuid: data.icon?.uuid || null,
          }
          this.image = data.image
          this.icon = data.icon
          this.image_uuid = data.image?.uuid || null
          this.icon_uuid = data.icon?.uuid || null
          let arr = []
          let obj = {
            attributes: this.image,
            clientValidationErrors: [],
            client_preview: data.image?.original_url,
            upload: {},
          }

          arr.push(obj)
          obj = {
            attributes: this.icon,
            clientValidationErrors: [],
            client_preview: data.icon?.original_url,
            upload: {},
          }
          arr.push(obj)

          this.$refs.imageMediaComponent.mediaLibrary.changeState(state => {
            state.media = [arr[0]]
          })
          this.$refs.iconMediaComponent.mediaLibrary.changeState(state => {
            state.media = [arr[1]]
          })
        })
    },
    loadFormData() {
      const $form = _.cloneDeep(this.form)
      this.form_data = $form
      if (this.isEdit) {
        if (!($form.icon instanceof File) && $form.icon !== 'delete') {
          delete $form.icon
        }
        // eslint-disable-next-line no-unused-vars
        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },
  },
}
</script>
